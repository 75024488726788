import clsx from 'clsx';
import { useState } from 'react';

import { useSequentialId } from 'lib/utils/sequentialId';
import { useIsMobile } from 'components/Responsive';
import { MAX_REVIEW } from 'lib/constants/listings';

const STARS = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];

const StarRating = ({
  onChange = null,
  value = -1,
  onBlur = null,
  size = 1,
  showLabels = false,
  disabled = false,
  spacing = '8px',
  starsLabels = STARS,
}) => {
  const isMobile = useIsMobile();

  if (isMobile && size > 2) size = 2;

  const sid = useSequentialId();

  const [hoverStars, setHoverStars] = useState(-1);

  const handleMouseEnter = (i) => {
    setHoverStars(i);
  };

  const handleMouseLeave = (_i) => {
    setHoverStars(-1);
    // setHoverStars(prev => prev === i ? -1 : prev);
  };

  const handleChange = (e) => {
    if (e.target.checked && !disabled) {
      onChange?.(Number(e.target.value));
      onBlur?.();
    }
  };

  const name = `stars-${sid}`;

  return (
    <>
      <span className="stars">
        {Array.from({ length: MAX_REVIEW + 1 }).map((_, i) => (
          <label
            key={i}
            onMouseEnter={disabled ? undefined : () => handleMouseEnter(i)}
            onMouseLeave={disabled ? undefined : () => handleMouseLeave(i)}
            title={`${value + 1} out of ${MAX_REVIEW + 1}`}
            className={clsx(
              'star button-reset',
              disabled && 'readonly',
              value === i ? 'selected' : value > i ? 'before-selected' : null,
              hoverStars === i ? 'hovered' : hoverStars > i ? 'before-hovered' : null,
            )}
          >
            {!disabled && (
              <input
                type="radio"
                name={name}
                className="sr-only"
                value={i}
                checked={value === i}
                onChange={handleChange}
              />
            )}
            <i className={`fas fa-star fa-${size}x`} aria-hidden />
            <span
              className={showLabels ? 'is-size-6 has-text-dark has-text-weight-bold' : 'sr-only'}
            >
              {starsLabels[i]}
            </span>
          </label>
        ))}

        <style jsx>{`
          @import 'styles/variables';

          $selected: #fac05e;
          $hovered: #fac05eaa;

          .stars {
            display: flex;
            margin: 0 -${spacing};

            &:focus-within {
              background: $grey-lightest;
              border-radius: 0.5rem;
            }
          }
          .star {
            cursor: pointer;
            &.readonly {
              cursor: default;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: ${spacing};
            color: $grey-light;
            transition: background 0.2s ease, color 0.2s ease;
            border-radius: 0.5rem;

            &.before-selected {
              color: $selected;
            }

            &.before-hovered {
              color: $hovered;
            }

            &.hovered {
              background: $grey-lightest;

              color: $hovered;
            }

            &.selected {
              color: $selected;
            }
          }
        `}</style>
      </span>
    </>
  );
};

export default StarRating;
