import * as _ from 'lodash-es';

export class PsuedoRandom {
  previous: number;

  constructor(seed: number | string) {
    if (typeof seed === 'string') {
      this.previous = _.sum(_.times(seed.length, (i) => seed.charCodeAt(i) * (i % 20))) + 592834;
    } else {
      this.previous = seed + 48039631;
    }
  }

  static hash(a: number) {
    a = a ^ 61 ^ (a >> 16);
    a += a << 3;
    a ^= a >> 4;
    a *= 0x27d4eb2d;
    a ^= a >> 15;
    return a;
  }

  /** Returns a random integer between 0 and 2^32 - 1 */
  next() {
    this.previous = PsuedoRandom.hash(this.previous);
    return this.previous;
  }

  shuffle<T>(array: T[]): T[] {
    return _.sortBy(array, () => this.next());
  }

  sample<T>(array: T[]): T {
    return array[this.next() % array.length];
  }
}
