import ImageGallery from 'react-image-gallery';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

import LazyImage from 'components/LazyImage';
import StarRating from 'components/StarRating';
import Link, { LinkProps } from 'components/Link';

import { formatCurrency } from 'lib/utils/currency';
import { getListingGalleryImages } from 'lib/images';
import { useQuery } from 'lib/hooks/query';
import type { ListingDoc, WithId } from 'schemas/types';

const MaybeLink: React.FC<
  Partial<
    Pick<
      LinkProps,
      'href' | 'route' | 'newTab' | 'className' | 'onClick' | 'style' | 'tabIndex' | 'children'
    >
  >
> = ({ href, route, newTab, children, ...rest }) => {
  return href || route ? (
    <Link href={href!} route={route} newTab={newTab} {...rest}>
      {children}
    </Link>
  ) : (
    <span {...rest}>{children}</span>
  );
};

export const ListingPhotoGallery: React.FC<{
  listing?: WithId<ListingDoc>;
  className?: string;
  newTab?: boolean;
}> = ({ listing, className, newTab }) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { id, photos } = listing || {};

  const href = id ? `/listings/${id}` : undefined;

  const items = useMemo(() => (listing ? getListingGalleryImages(listing) : null), [listing]);

  return (
    <MaybeLink
      href={href}
      newTab={newTab}
      className={clsx(
        'ListingPhotoGallery shadowed has-background-light is-rounded is-relative is-block',
        className,
      )}
      style={{
        height: 200,
        marginBottom: 4,
      }}
      tabIndex={-1}
      onClick={(e) => {
        if ((e.target as HTMLElement)?.closest?.('button,a')?.tagName !== 'A') {
          e.preventDefault();
        }
      }}
    >
      {items?.length ? (
        <ImageGallery
          items={items}
          lazyLoad
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          onBeforeSlide={setPhotoIndex}
          // @ts-expect-error override type
          renderItem={(item: NonNullable<typeof items>[number]) => (
            <LazyImage
              src={item.originalImage}
              alt={item.originalAlt}
              title={item.originalTitle}
              className="image-gallery-image"
              objectFit="cover"
              sizes="400px"
              loading={photos?.length && item.index === 0 ? 'eager' : 'lazy'} // TODO: use `priority` instead?
              style={{ height: '100%' }}
            />
          )}
        />
      ) : null}
      {photos && photos.length > 1 ? (
        <div className="listing-photo-bubbles">
          <div style={{ transform: `translateX(${50 - photoIndex * 16}px)` }}>
            {photos.map((_p, i) => (
              <span key={i} className={i === photoIndex ? 'is-active' : undefined} />
            ))}
          </div>
        </div>
      ) : null}

      <style jsx global>{`
        .ListingPhotoGallery {
          .image-gallery-slide {
            height: 200px;
          }

          .image-gallery-svg {
            width: 28px;
            height: 56px;
          }

          .image-gallery-image {
            transition: transform 0.3s ease;
          }

          .image-gallery-image:hover {
            transform: scale(1.1, 1.1);
          }
        }
      `}</style>
    </MaybeLink>
  );
};

export const ListingItem: React.FC<{
  listing?: WithId<ListingDoc>;
  expanded?: boolean;
  hovered?: boolean;
  isMapMarker?: boolean;
  inSearchPage?: boolean;
  onHover?: (id: string, hovered: boolean) => void;
  newTab?: boolean;
}> = ({
  listing,
  expanded = false,
  hovered = false,
  isMapMarker = false,
  inSearchPage = false,
  onHover,
  newTab = true,
}) => {
  const { id, title, price, type } = listing || {};

  // TODO: also display this on the list view once we can query it in bulk more efficiently
  // and once we're more confident in our review system
  const ratingInfo = useQuery(isMapMarker ? `/api/listings/${id}/rating` : null).data;

  const href = id ? `/listings/${id}` : undefined;

  return (
    <div
      title={title}
      onMouseEnter={onHover && id ? () => onHover(id, true) : undefined}
      onMouseLeave={onHover && id ? () => onHover(id, false) : undefined}
      data-lid={listing?.id}
      className={clsx(
        'listing-item',
        !listing && 'is-skeleton',
        (expanded || hovered) && 'listing-item--hover',
        isMapMarker
          ? 'listing-item--marker'
          : inSearchPage
          ? 'column is-12-tablet is-4-desktop'
          : 'column is-6-tablet is-4-desktop',
      )}
    >
      <ListingPhotoGallery listing={listing} newTab={newTab} />
      <p className="is-size-7 is-capitalized has-text-weight-bold has-text-success empty-skeleton">
        {type || '\u00A0'}
      </p>
      <p className="empty-skeleton">
        <MaybeLink className="has-text-weight-bold has-text-dark" href={href} newTab={newTab}>
          {title || '\u00A0'}
        </MaybeLink>
      </p>
      <div className="is-size-7 has-text-grey has-text-weight-bold flex justify-between">
        <p className="empty-skeleton">
          {price != null ? <>{formatCurrency(price)}/night</> : '\u00A0'}
        </p>
        {ratingInfo && ratingInfo.count > 0 ? (
          <p className="flex items-center">
            <StarRating value={ratingInfo.average} spacing="0px" disabled />
            <span className="ml-1">({ratingInfo.count})</span>
          </p>
        ) : null}
      </div>

      <style jsx>{`
        @import 'styles/variables';

        .listing-item {
          padding: 8px;
          box-sizing: border-box;
          border-radius: 8px;

          transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out,
            box-shadow 0.3s ease-in-out;
          border: 2px solid transparent;

          &--hover,
          &:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            border-color: #ddd;
          }

          &:focus-within {
            border-color: #ddd;
          }

          &.is-skeleton {
            pointer-events: none;

            .empty-skeleton {
              background: #f5f5f5; // linear-gradient(90deg, #f5f5f5 0%, #e8e8e8 50%, #f5f5f5 100%);
              border-radius: 0.25rem;
              width: 10em;
              height: 1.3em;
              margin-bottom: 0.25rem;
            }
          }
        }
      `}</style>
    </div>
  );
};
