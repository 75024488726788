import * as _ from 'lodash-es';

import Link from 'components/Link';
import LazyImage from 'components/LazyImage';
import { loadModuleMap } from 'lib/utils';
import { getPath } from 'lib/router';
import { COLORS, COLOR_LIST } from 'lib/constants/pages';
import { PsuedoRandom } from 'lib/utils/random';

export const GEOS_IMAGE_MAP = loadModuleMap(
  import.meta.webpackContext('images/popular_geos', {
    recursive: false,
    regExp: /^\.\/.*?\.(jpe?g|png)$/,
  }),
);

export const LocationPreview = ({ slug, name, color = COLORS.green }) => {
  const href = getPath('stays.show', { slug });

  return (
    <div className="column is-4">
      <div
        className="rounded-lg"
        style={{
          background: color,
        }}
      >
        <Link href={href} aria-hidden tabIndex={-1}>
          <LazyImage
            src={GEOS_IMAGE_MAP[slug]}
            width={600}
            height={300}
            style={{ height: 200 }}
            alt={name}
          />
        </Link>
        <div className="flex items-center justify-between px-4" style={{ height: 64 }}>
          <span className="has-text-weight-semibold has-text-white">{name}</span>

          <Link
            className="button is-rounded is-small has-text-weight-semibold"
            href={href}
            style={{ color }}
          >
            Find Stays
          </Link>
        </div>
      </div>

      <style jsx>{`
        span {
          display: block;
          margin: 10px 0;
        }

        .column :global(.button) {
          height: 34px;
          border: none;
          &:hover {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
          }
        }

        .rounded-lg {
          border-radius: 1rem;
          overflow: hidden;
        }

        .column :global(.LazyImage) {
          transform-origin: center bottom;
          transition: transform 0.2s ease;
          &:hover {
            transform: scale(1.05, 1.05);
          }
        }
      `}</style>
    </div>
  );
};

export const LocationPreviewList = ({ geos }) => {
  const pRand = new PsuedoRandom('popular-geos');

  return (
    <div className="columns is-multiline">
      {geos.map((geo, i) => (
        <LocationPreview key={i} color={pRand.sample(COLOR_LIST)} {...geo} />
      ))}
    </div>
  );
};
