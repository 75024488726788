import * as _ from 'lodash-es';

import { parseNum } from 'lib/utils';

export const convertCurrency = {
  toStripe: (dollars: number): number => Math.round(dollars * 100.0),
  fromStripe: (cents: number): number => _.round(cents / 100.0, 2),
};

const formatters = {
  usd: (dollars: number, short?: boolean) => `$${short ? dollars : dollars.toFixed(2)}`,
};

// TODO: handle more currencies when expanding outside USA
// TODO: stop allowing `string` input
export const formatCurrency = (
  dollars: number | string, // in the form 1.23 (not 123)
  { currency = 'usd', short = false }: { currency?: keyof typeof formatters; short?: boolean } = {},
): string | null => {
  const num = parseNum(dollars);
  if (num === null) return null;
  return formatters[currency](_.round(num, 2), short);
};
